import { render, staticRenderFns } from "./companyaccountdetail-add-or-update.vue?vue&type=template&id=47a478fd&"
import script from "./companyaccountdetail-add-or-update.vue?vue&type=script&lang=js&"
export * from "./companyaccountdetail-add-or-update.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports